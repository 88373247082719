.span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.title {
    position: relative;
    height: 254px;
    display: flex;
    padding: 32px 40px;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px 16px 80px 0px;
}

.success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.success img {
    margin: 55px 0px 24px 0px;
}

.success span {
    margin-bottom: 73px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}